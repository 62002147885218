body{
  background-color: #050A11;
}

.ripple {
  width: 170px;
  height: 170px;
  box-sizing:10px;
  position:relative;
}

.ripple:before {
  animation:ripple 2s ease-out infinite;
  border:solid 2px rgb(234, 87, 14);
  border-radius:100%;
  bottom:0;
  box-sizing:10px;
  content:"";
  left:0;
  position:absolute;
  right:0;
  top:0;
}

.ripple:after {
  animation:ripple 2s 1s ease-out infinite;
  border:solid 2px rgb(234, 87, 14);
  border-radius:100%;
  bottom:0;
  box-sizing:10px;
  content:"";
  left:0;
  position:absolute;
  right:0;
  top:0;
}

@keyframes ripple {
  0% {
    opacity:.25;
  }
  100% {
    border-radius:100%;
    opacity:0;
    transform:scale(2);
  }
}

.clipPath{
  clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%);
}

.bg-size{
  background-size: 100% 100%;
}

.progress-bar-completed{
  transition: width 0.2s ease-in-out;
}
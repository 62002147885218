@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Manrope:wght@200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'BwGradualDemo';
  src: url('./fonts/BwGradualDEMO-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.inter {
  font-family: 'BwGradualDemo', sans-serif;
}

.poppins {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
}

.manrope {
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.bg-color{
    color: #001e33;
}

@keyframes marquee-forward {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}